<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, decimal, maxLength, requiredIf } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Modifier un contrat",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Modifier un contrat",
      suppliers: [],
      contractForm: {
        uuid: null,
        contractRef: "",
        contractSupplier: "",
        contractType: "",
        contractPeriodFrom: "",
        contractPeriodTo: "",
        contractAmnt: "",
        contractComments: "",
        // catering contract
        contractAllocatedHostingAmmount: "",
        contractAllocatedCateringAmmount: "",
        contractAllocated3dAmmount: "",
        // gardenig contract
        contractPesticidesAmountAllocated: "",
        contractConstructionsAmountAllocated: "",
        contractRollingEquipmentsAmountAllocated: "",
        contractMaintenanceAmountAllocated: "",
        // other contract
        contractSignatureDate: "",
        contractEffectiveDate: "",
      },
      types: [
        {
          id: "catering",
          name: "Full Catering",
        },

        {
          id: "Maintenance",
          name: "Maintenance",
        },

        {
          id: "gardening",
          name: "Espace Vert",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Contrats",
          href: "/",
        },
        {
          text: "Modifier un contrat",
          active: true,
        },
      ],
    };
  },

  validations: {
    contractForm: {
      contractRef: {
        required,
        maxLength: maxLength(64),
      },
      contractSupplier: { required },
      contractType: { required },
      contractPeriodFrom: { required },
      contractPeriodTo: { required },
      contractAmnt: { required, decimal },
      contractAllocatedHostingAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering"
        }),
        
      },
      contractAllocatedCateringAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering"
        })
      },
      contractAllocated3dAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering"
        })
      },
    },
  },

  mounted() {
    this.contractForm.uuid = this.$route.params.uid;
    this.fetchContractDetails();
  },

  created() {
    this.fetchSuppliersList();
  },

  methods: {
    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchContractDetails() {
      var _this = this;
      this.$http
        .post(
          "/contracts/contracts/getSingleContractData/" + this.contractForm.uuid
        )

        .then((res) => {
          var status = res.data.original.status;
          var contract = res.data.original.data.contract;
          switch (status) {
            case 200:
              _this.contractForm.uuid = contract.uuid;
              _this.contractForm.contractRef = contract.ref;
              _this.contractForm.contractSupplier = contract.supplier_id;
              _this.contractForm.contractType = contract.typePlain;
              _this.contractForm.contractPeriodFrom = contract.start_date;
              _this.contractForm.contractPeriodTo = contract.end_date;

              _this.contractForm.contractAmnt = contract.amountsPlain.planned_amount;
              _this.contractForm.contractAllocatedHostingAmmount = contract.amountsPlain.hosting_amount_allocated;
              _this.contractForm.contractAllocatedCateringAmmount = contract.amountsPlain.catering_amount_allocated;
              _this.contractForm.contractAllocated3dAmmount = contract.amountsPlain._3d_amount_allocated;

              _this.contractForm.contractPesticidesAmountAllocated = contract.amountsPlain.pesticides_amount_allocated;
              _this.contractForm.contractConstructionsAmountAllocated = contract.amountsPlain.constructions_amount_allocated;
              _this.contractForm.contractRollingEquipmentsAmountAllocated = contract.amountsPlain.rolling_equipments_amount_allocated;
              _this.contractForm.contractMaintenanceAmountAllocated = contract.amountsPlain.maintenance_amount_allocated;

              _this.contractForm.contractSignatureDate = contract.contract_signature_date;
              _this.contractForm.contractEffectiveDate = contract.contract_effective_date;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/contracts/contracts/update", this.contractForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "base.contracts.contracts.index" });
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Référence *</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractRef"
                      type="text"
                      class="form-control"
                      @blur="$v.contractForm.contractRef.$touch"
                      :class="{ 'is-invalid': submitted && $v.contractForm.contractRef.$error, }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractRef.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractRef.required">La référence du contrat est obligatoire.</span> <br>
                      <span v-if="!$v.contractForm.contractRef.maxLength">La taille minimum du champs est: <b>{{ this.vrules.contractRef.maxLength }}</b> Caractères.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-password-input">Prestataire *</label>
                    <select
                      id="projectname"
                      v-model="contractForm.contractSupplier"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.contractForm.contractSupplier.$error, }"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="supplier in suppliers"
                        v-bind:value="supplier.id"
                        v-bind:key="supplier.id"
                      >
                        {{ supplier.name }}
                      </option>
                    </select>
                    <div v-if="submitted && $v.contractForm.contractSupplier.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractSupplier.required">Le prestataire est obligatoire.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type *</label>
                    <select
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractType.$error,
                      }"
                      v-model="contractForm.contractType"
                    >
                      <option value=""></option>
                      <option
                        v-for="type in types"
                        v-bind:value="type.id"
                        v-bind:key="type.id"
                      >
                        {{ type.name }}
                      </option>
                    </select>
                    <div
                      v-if="
                        submitted && $v.contractForm.contractType.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractType.required"
                        >Le Type est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <label for="formrow-inputState"
                      >Montant total (en DZD) *</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractAmnt"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAmnt.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.contractForm.contractAmnt.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractAmnt.required">La montant total du contrat est obligatoire.</span>
                      <span v-if="!$v.contractForm.contractAmnt.decimal">La montant est invalide.</span>
                     
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="contractForm.contractType === 'catering'">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant alloué Hébergemet</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedHostingAmmount"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedHostingAmmount.$error,
                      }"
                      placeholder=""
                    />
                  <div v-if="submitted && $v.contractForm.contractAllocatedHostingAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedHostingAmmount.required">Le Montant alloué Hébergemet est obligatoire.</span>
                  </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant alloué Restauration</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocatedCateringAmmount"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocatedCateringAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocatedCateringAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocatedCateringAmmount.required">Le Montant alloué Restauration est obligatoire.</span>
                    </div>
                  </div>

                  
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant alloué 3D</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractAllocated3dAmmount"
                      type="text"
                      class="form-control"
                       :class="{
                        'is-invalid':
                          submitted && $v.contractForm.contractAllocated3dAmmount.$error,
                      }"
                      placeholder=""
                    />
                    <div v-if="submitted && $v.contractForm.contractAllocated3dAmmount.$error" class="invalid-feedback">
                      <span v-if="!$v.contractForm.contractAllocated3dAmmount.required">Le Montant alloué 3D est obligatoire.</span>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div class="row" v-if="contractForm.contractType === 'gardening'">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant Entretien</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractMaintenanceAmountAllocated"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractMaintenanceAmountAllocated
                            .$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm.contractMaintenanceAmountAllocated
                            .$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm.contractMaintenanceAmountAllocated
                            .required
                        "
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Fournitures de produits Phytosanitaires</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractPesticidesAmountAllocated"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractPesticidesAmountAllocated
                            .$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm.contractPesticidesAmountAllocated
                            .$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm.contractPesticidesAmountAllocated
                            .required
                        "
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Montant Réalisation</label
                    >
                    <input
                      id="projectname"
                      v-model="
                        contractForm.contractConstructionsAmountAllocated
                      "
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractConstructionsAmountAllocated
                            .$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm.contractConstructionsAmountAllocated
                            .$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm.contractConstructionsAmountAllocated
                            .required
                        "
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Montant Matériel roulant</label>
                    <input
                      id="projectname"
                      v-model="
                        contractForm.contractRollingEquipmentsAmountAllocated
                      "
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm
                            .contractRollingEquipmentsAmountAllocated.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.contractForm
                            .contractRollingEquipmentsAmountAllocated.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.contractForm
                            .contractRollingEquipmentsAmountAllocated.required
                        "
                        >La montant 3D est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputCity"
                      >Date début de validité *</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractPeriodFrom"
                      type="date"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractPeriodFrom.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="
                        submitted && $v.contractForm.contractPeriodFrom.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractPeriodFrom.required"
                        >La date début du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputState">Date d'expiration *</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractPeriodTo"
                      type="date"
                      class="form-control"
                      placeholder=""
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.contractForm.contractPeriodTo.$error,
                      }"
                    />
                    <div
                      v-if="
                        submitted && $v.contractForm.contractPeriodTo.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.contractForm.contractPeriodTo.required"
                        >La date fin du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date de signature</label>
                    <input
                      id="projectname"
                      v-model="contractForm.contractSignatureDate"
                      type="date"
                      class="form-control"
                      placeholder=""
                    />
                    
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="formrow-inputState"
                      >Date d’entrée en vigueur</label
                    >
                    <input
                      id="projectname"
                      v-model="contractForm.contractEffectiveDate"
                      type="date"
                      class="form-control"
                      placeholder=""
                    />
                    
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>